<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
        >
            <template v-slot:filters>
                <AccountRoleFilter
                    ref="accountRoleFilter"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCode="filterPayload.manCode"
                    v-model:role="filterPayload.role"
                    v-model:bgCode="filterPayload.bgCode"
                />
                <Textbox
                    name="endDate"
                    type="date"
                    max="today"
                    :min="minEndDate"
                    :onlyMonth="true"
                    :isMonthLastDay="true"
                    :required="true"
                    :title="filterTitles.endDate"
                    v-model="filterPayload.endDate"
                    :tips="dateFilterTips"
                />
                <Dropdown
                    v-if="mode !== '1'"
                    name="relationMode"
                    :disabled="true"
                    :title="filterTitles.relationMode"
                    :options="filterOptions.relationMode"
                    v-model="filterPayload.relationMode"
                />
            </template>
        </FilterModal>
    </div>
</template>

<script>
import Textbox from '@/components/Textbox.vue'
import Dropdown from '@/components/Dropdown.vue'
import FilterModal from '@/containers/FilterModal.vue'
import AccountRoleFilter from '@/containers/AccountRoleFilter.vue'
import { detailFilterTitles } from '@/assets/javascripts/report/setting'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'DetailFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    components: {
        Textbox,
        Dropdown,
        FilterModal,
        AccountRoleFilter
    },
    props: {
        mode: {
            type: String,
            default: '1'
        },
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    relationMode: '',
                    manCode: '',
                    endDate: '',
                    bgCode: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        stylerList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        resetFilter: function () {
            this.filterPayload = {
                endDate: this.$formatDate(this.$getDate()),
                ...filterDefault.detail
            }
            // includes mType, manCode(payload), role, bgCode(payload)
            this.$refs.accountRoleFilter.resetFilter()
        },
        getFilterData: function () {
            /*
             ** 若送api的payload有異動 要重設進階篩選
             ** 若api的payload無異動 只需做進階篩選
             */
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
            }
            this.filterLog = this.$getFilterLog(this.filterPayload)
            this.setFilterLogEndDate()
            this.$store.commit('set', {
                tableDeep: 0
            })
        },
        setFilterLogEndDate: function () {
            if (this.filterLog.endDate) {
                this.filterLog.endDate.label = this.endDateLabel
            }
        }
    },
    computed: {
        filterOptions: function () {
            return {
                relationMode: [
                    {
                        label: '輔導線',
                        value: 'relation'
                    }
                ]
            }
        },
        endDateLabel: function () {
            /* 若模式一 查詢日改查詢月，值需把日trim掉 */
            const endDate = this.filterPayload.endDate
            const period = endDate.slice(0, 7)
            return period
        },
        dateFilterTips: function () {
            return '最早可查詢月份為單位業績明細報表發行月2021/01'
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            allOption: [
                {
                    label: '全部',
                    value: ''
                }
            ],
            filterTitles: {},
            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            // 保存歷史受理只追朔到此報表開始發行之工作月2021/01
            minEndDate: '2021-01-01'
        }
    },
    mounted() {
        this.resetFilter()
        this.$nextTick(async () => {
            const accountRoleTitles = this.$refs.accountRoleFilter.filterTitles
            this.filterTitles = {
                ...accountRoleTitles,
                ...detailFilterTitles,
                _seq: [...accountRoleTitles._seq, ...detailFilterTitles._seq]
            }
            // this.getFilterData()
        })
    }
}
</script>

<style lang="scss" scoped></style>
